<template>
  <van-dialog 
    v-model="dialogShow" 
    :title="title" 
    show-cancel-button
    @confirm="handleSubmit"
    @cancel="handleCancel"
  >
    <div v-if="approvalType === 'referral'" class="referral-wrap">
      <span class="title">转审人</span>
      <el-select
        ref="select"
        :clearable="showClose"
        @hook:mounted="cancalReadOnly"
        @visible-change="cancalReadOnly"
        @blur.native.capture="onblur"
        :popper-append-to-body="false"
        v-model="approverId" 
        filterable 
        placeholder="请选择"
      >
        <el-option
          v-for="item in personList"
          :key="item.personId"
          :label="`${item.personName}${item.phone}`"
          :value="item.personId">
        </el-option>
      </el-select>
    </div>
    <van-field
      v-model="remark"
      rows="2"
      autosize
      label="备注"
      type="textarea"
      maxlength="50"
      placeholder="请输入备注"
      show-word-limit
    />
  </van-dialog>
</template>

<script>
import Vue from "vue";
import { Dialog, Field, Popup, Picker, Cell, Toast } from 'vant';
Vue.use(Dialog).use(Field).use(Popup).use(Picker).use(Cell).use(Toast);
import { getAllPerson, auditPass, auditReject, referral} from '@/api/reimburse';

export default {
  name: 'Approval',
  props:{
    approvalType: {
      type: String,
      require: true,
      description: '审批类型'
    },
    billNo: {
      type: String,
      require: true,
    },
  },
  data(){
    return {
      dialogShow: false,
      remark: '',
      title: '',
      approverId: '',
      personList: [],
    }
  },
  watch: {
    approvalType(newValue, oldValue){
      if(newValue === "reject"){
        this.title = '驳回'
      } else if(newValue === "pass"){
        this.title = '通过'
      } else if(newValue === "referral"){
        this.title = '转审'
        this.getPersonList()
      }
    }
  },
  methods: {
    // 失去焦点
    onblur() {
      setTimeout(() => {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
          // this.$refs.select.blur();
        }
        this.showClose = false
        var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
        window.scrollTo(0, Math.max(scrollHeight - 1, 0));
      }, 100);
    },
    cancalReadOnly(onOff) {
      this.showClose=true;
      this.$nextTick(() => {
          if (!onOff) {
              const {select} = this.$refs;
              const input = document.querySelector('.el-input__inner');
              input.removeAttribute('readonly');
          }
      });
    },
    changeDialogShow(type){
      this.dialogShow = type
    },
    getPersonList(){
      getAllPerson().then(res => {
        this.personList = res.resultData;
      })
    },
    handleAfter(){
      Toast.success("提交成功");
      if(this.$listeners.getList){
        this.$emit('getList')
      } else {
        this.$router.go(-1)
      }
    },
    handleSubmit(){
      if(this.approvalType === "reject"){
        auditReject({ billNo: this.billNo, remark: this.remark }).then(
          (res) => {
            if (res.success) {
              this.handleAfter()
            } else {
              Toast.fail(res.resultMsg);
            }
          }
        );
      } else if(this.approvalType === "pass"){
        auditPass({ billNo: this.billNo, remark: this.remark }).then(
          (res) => {
            if (res.success) {
              this.handleAfter()
            } else {
              Toast.fail(res.resultMsg);
            }
          }
        );
      } else if(this.approvalType === "referral"){
        if(!this.approverId){
          Toast.fail('请先选择转审人');
          return
        }
        referral({
          approverId: this.approverId,
          billNo: this.billNo,
          remark: this.remark,
        }).then((res) => {
          if (res.success) {
            this.handleAfter()
          } else {
            Toast.fail(res.resultMsg);
          }
        });
      }
    },
    handleCancel(){
      this.approverId = '';
      this.remark = '';
    }
  }
}
</script>

<style lang="scss">
.van-dialog{
  overflow: visible;
  .el-select-dropdown{
    top: auto !important;
    left: auto !important;
  }
  .van-dialog__footer{
    .van-button{
      border-radius: 30%;
    }
  }
}
.referral-wrap{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 16px;
  .title{
    font-size: 14px;
    width: 6.2em;
  }
  .el-select{
    .el-input{
      .el-input__inner{
        border: 0;
      }
    }
  }
}
.el-select-dropdown{
  z-index: 9999 !important;
}
</style>