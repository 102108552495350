<template>
  <div class="list-top-wrap">
    <van-nav-bar :title="title" @click-left="onClickLeft" left-arrow></van-nav-bar>
    <van-tabs v-model="tabActive" @change="handleTab">
      <van-tab :name="1" title="将要审批"></van-tab>
      <van-tab :name="2" title="未审批"></van-tab>
      <van-tab :name="3" title="已审批"></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Tab, Tabs } from 'vant';
Vue.use(NavBar).use(Tab).use(Tabs);

export default {
  name: "ListTop",
  data() {
    return {
      tabActive: 2
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  mounted() {
  },

  methods: {
    onClickLeft(){
      this.$router.go(-1)
    },
    handleTab(e){
      this.$emit('handleStatus', e)
    }
  },
};
</script>

<style lang="scss" scoped>
.list-top-wrap{
  position: sticky;
  top: 0;
  z-index: 9999;
}

</style>
