<template>
  <div class="list-item-wrap">
    <div @click="handleView">
      <!-- 头部基础信息 -->
      <div class="top-wrap">
        <div class="top-base-wrap">
          <div class="top-icon">{{ icon }}</div>
          <div class="top-base">
            <div class="top-name">{{ leftValue }}</div>
            <div class="top-name-title">{{ leftName }}</div>
          </div>
        </div>
        <div class="top-number-wrap">
          <div class="top-number">{{ rightValue }}</div>
          <div class="top-number-title">{{ rightName }}</div>
        </div>
      </div>

      <van-divider />

      <!-- 详情字段 -->
      <div class="detail-wrap">
        <div v-for="(item,index) in detailList" :key="index" class="detail-item">
          <div class="detail-title">{{ item.title }}</div>
          <div class="detail-value">{{ detail[item.key] }}</div>
        </div>
      </div>

      <van-divider />

      <!-- 申请时间及详情 -->
      <div class="time-wrap">
        <div class="time">申请时间：{{ detail.createTime || detail.createDate }}</div>
        <div class="view" @click="handleView">查看 <van-icon name="arrow" /></div>
      </div>

      <van-divider />
    </div>

    <!-- 底部操作按钮 -->
    <div v-if="status === 2" class="btn-wrap">
      <div class="reject" @click.stop="handleApproval('reject')">
        <van-icon name="revoke" />
        驳回
      </div>
      <div class="right">
        <div class="referral" @click.stop="handleApproval('referral')">
          <van-icon name="share-o" />
          转审
        </div>
        <div class="pass">
          <van-button @click.stop="handleApproval('pass')" plain type="info" size="small">通过</van-button>
        </div>
      </div>
    </div>

    <approval-dialog @getList="handleGetList" :billNo="billNo || detail.billNo" :approvalType="approvalType" ref="approval"  />
  </div>
</template>

<script>
import Vue from 'vue';
import { Divider, Icon} from 'vant';
import { detail } from '@/api';
import ApprovalDialog from '@/components/business/approvalDialog/index.vue'

Vue.use(Divider).use(Icon);
export default{
  name: 'ListItem',
  props: {
    nextUrl: {
      type: Function,
      require: true,
      description: '跳转下一页的函数'
    },
    detailList: {
      type: Array,
      require: true,
      description: '中心部分的详情字段规则'
    },
    detail: {
      type: Object,
      require: true,
      description: '所有字段'
    },
    leftName: {
      require: true,
      description: 'top左边的名字(灰色字体)'
    },
    leftValue: {
      require: true,
      description: 'top左边的值(加粗加大)'
    },
    rightName: {
      require: true,
      description: 'top右边的名字(灰色字体)'
    },
    rightValue: {
      require: true,
      description: 'top右边的值(加粗加大)'
    },
    icon: {
      type: String,
      require: true,
      description: 'icon头像内容'
    },
    status: {
      type: Number,
      require: true,
      description: 'status状态'
    },
    billNo:{
      type: String,
      description: '自定义审批号'
    }
  },
  data(){
    return {
      approvalType: '',
    }
  },
  components: {
    ApprovalDialog
  },
  methods: {
    handleView(){      
      this.$emit('nextUrl', this.detail)
    },
    handleApproval(type){
      this.approvalType = type;
      this.$refs.approval.changeDialogShow(true);
    },
    handleGetList(){
      this.$emit('getList', 2)
    }
  }
}
</script>

<style lang="scss">
.list-item-wrap{
  margin: 10px 14px;
  padding: 14px 18px;
  border-radius: 10px;
  background-color: #fff;
  .top-wrap{
    display: flex;
    justify-content: space-between;
    padding: 0 6px;
    .top-base-wrap{
      display: flex;
      .top-icon{
        min-width: 0.36rem;
        max-width: 0.36rem;
        min-height: 0.36rem;
        max-height: 0.36rem;
        background-color: $main;
        color: #fff;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        overflow: hidden;
      }
      .top-base{
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top-name{
          font-weight: 600;
          font-size: 0.15rem;
        }
        .top-name-title{
          color: $gray;
          font-size: 0.12rem;
        }
      }
    }
    .top-number-wrap{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .top-number{
        font-weight: 600;
        font-size: .16rem;
      }
      .top-number-title{
        font-size: .12rem;
        color: $gray;
      }
    }
  }
  .detail-wrap{
    padding: 0 6px;
    .detail-item{
      display: flex;
      margin-bottom: 6px;
      font-size: .12rem;
      .detail-title{
        color: $gray;
        min-width: 1rem;
      }
    }
  }
  .time-wrap{
    display: flex;
    justify-content: space-between;
    font-size: .12rem;
    padding: 0 6px;
    .time{
      color: $gray;
    }
    .view{
      color: $main;
    }
  }
  .btn-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    .reject{
      color: $gray;
    }
    .right{
      display: flex;
      align-items: center;
      .pass{
        margin-left: 20px;
      }
    }
  }
}
</style>