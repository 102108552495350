import request from "../utils/request"
const baseURL = '/opt/'
// 登陆
export function login(params) {
  return request ({
    url: '/system/login/wechat',
    method: 'post',
    data: params
  })
}
// 新增
export function getUser() {
  return request ({
    url: `${baseURL}expense/add`,
    method: 'post',
  })
}

// 我的流程
// 审核查看接口
export function getByPerson(params) {
  return request ({
    url: `${baseURL}auditPerson/getByPerson`,
    method: 'post',
    data: params
  })
}
// 请款
// 查询
export function pageQueryP(params) {
  return request ({
    url: `${baseURL}expense/purchase/pageQuery`,
    method: 'post',
    data: params
  })
}
// 请款单查询
export function detail(params) {
  return request ({
    url: `${baseURL}expense/purchase/detail`,
    method: 'post',
    data: params
  })
}
// 合同查询列表
export function contractList(params) {
  return request ({
    url: `${baseURL}expense/purchase/contractList`,
    method: 'post',
    data: params
  })
}
// 合同相关信息
export function contRelateInfo(params) {
  return request ({
    url: `${baseURL}expense/purchase/contRelateInfo`,
    method: 'post',
    data: params
  })
}
// 费用产品列表
export function productList(params) {
  return request ({
    url: `${baseURL}expense/purchase/productList`,
    method: 'post',
    data: params
  })
}
// 款项用途
export function paymentUseList(params) {
  return request ({
    url: `${baseURL}expense/purchase/paymentUseList`,
    method: 'post',
    data: params
  })
}
// 付款公司列表
export function companyList(params) {
  return request ({
    url: `${baseURL}expense/companyList`,
    method: 'post',
    data: params
  })
}
// 支出渠道
export function channelList(params) {
  return request ({
    url: `${baseURL}expense/channelList`,
    method: 'post',
    data: params
  })
}
// 暂存
export function tempStorageP(params) {
  return request ({
    url: `${baseURL}expense/purchase/tempStorage`,
    method: 'post',
    data: params
  })
}
// 提交
export function submitP(params) {
  return request ({
    url: `${baseURL}expense/purchase/submit`,
    method: 'post',
    data: params
  })
}

// 报销
// 查询
export function pageQueryR(params) {
  return request ({
    url: `${baseURL}expense/reimburse/pageQuery`,
    method: 'post',
    data: params
  })
}
// 查询具体详情页面
export function detailRei(params) {
  return request ({
    url: `${baseURL}expense/reimburse/detail`,
    method: 'post',
    data: params
  })
}
// 款项用途
export function paymentUseListRei(params) {
  return request ({
    url: `${baseURL}expense/reimburse/paymentUseList`,
    method: 'post',
    data: params
  })
}
// 暂存
export function tempStorageRei(params) {
  return request ({
    url: `${baseURL}expense/reimburse/tempStorage`,
    method: 'post',
    data: params
  })
}
// 提交
export function submitRei(params) {
  return request ({
    url: `${baseURL}expense/reimburse/submit`,
    method: 'post',
    data: params
  })
}
// 关闭
export function close(params) {
  return request ({
    url: `${baseURL}expense/close`,
    method: 'post',
    data: params
  })
}
// 审批
// 列表
export function pageQueryA(params) {
  return request ({
    url: `${baseURL}expense/auditing/pageQuery`,
    method: 'post',
    data: params
  })
}